<template>
    <user-dashboard-layout>
        <v-data-table
            v-if="products.data"
            :loading="loading"
            :page="products.meta.current_page"
            :items-per-page="products.meta.per_page * 1"
            :server-items-length="products.meta.total"
            @pagination="paginate"
            :headers="headers"
            :items="products.data"
            class="elevation-1"
        >
            <template v-slot:header="{ props }">
                <thead>
                <tr>
                    <th v-for="header in props.headers" :key="header.text">
                        {{ $t('general.' + header.text.toLowerCase()) }}
                    </th>
                </tr>
                </thead>
            </template>
            <template v-slot:item.categories="{ item }">
                <div>
                    {{ item.categories.flatMap(i => i.name).join(", ") }}
                </div>
            </template>
            <template v-slot:item.image="{ item }">
                <img
                    :src="item.image"
                    style="width: auto; height: 100%; border-radius: 8px; margin-top: 5px;"
                />
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('general.my-product') }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="newItem"
                            >
                                {{ $t('general.new-item') }}
                            </v-btn>
                        </template>
                        <v-card class="position-relative">
                            <v-btn @click="close" class="close_dialog" small rounded>&times;</v-btn>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container id="form-product" class="pa-0">
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <div class="title-field">{{ $t('general.product-name') }}</div>
                                            <input type="text"
                                                   v-model="product.name"
                                                   class="input-text"
                                            >
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <div class="title-field">{{ $t('general.description') }}</div>
                                            <textarea name="" id="" cols="30" rows="3"
                                                      v-model="product.description"
                                                      class="form-product__textarea"
                                                      v-html="$t('general.type-here-description')"
                                            ></textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <div class="title-field">{{ $t('general.allergenen') }}</div>
                                            <textarea name="" cols="30" rows="3"
                                                      v-model="product.allergenen"
                                                      class="form-product__textarea"
                                                      v-html="$t('general.type-here-allergenen')"
                                            ></textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <div class="title-field">{{ $t('general.ingredients') }}</div>
                                            <textarea name="" cols="30" rows="3"
                                                      v-model="product.ingredients"
                                                      class="form-product__textarea"
                                                      v-html="$t('general.type-here-ingredients')"
                                            ></textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-switch
                                                v-model="product.is_active"
                                                :label="$t('general.active')"
                                                :true-value="1"
                                                :false-value="0"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row style="margin-top: -7px;">
                                        <v-col cols="3" class="py-0 pr-0">
                                            <div class="title-field">{{ $t('general.price') }}</div>
                                            <input type="number"
                                                   v-model="product.price"
                                                   class="input-text"
                                                   step="0.1"
                                            >
                                        </v-col>
                                        <v-col cols="9" class="py-0">
                                            <div class="title-field">{{ $t('general.categories') }}</div>
                                            <div class="wrap_select">
                                                <v-select
                                                    class="categories_select"
                                                    v-model="product.categories"
                                                    :items="categories.data"
                                                    item-text="name"
                                                    item-value="id"
                                                    multiple
                                                    outlined
                                                />
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row style="margin-top: -24px;">
                                        <v-col cols="12">
                                            <div class="title-field">{{ $t('general.image') }}</div>
                                            <v-text-field
                                                class="d-none"
                                                v-model="product.image"
                                                :label="$t('general.image-url')"
                                            ></v-text-field>
                                            <dropzone
                                                :mode_dropzone_height="image_url ? '200px' : '175px'"
                                                :current_url="image_url"
                                                @input="handleImgProduct"
                                                v-model="image_url"></dropzone>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="d-flex justify-center flex-wrap">
                                            <v-card v-if="show_view_variation" class="variation-card">
                                                <v-card-title class="pl-0 pb-0" style="margin-top: -23px;">
                                                    {{ $t('general.variations') }}
                                                </v-card-title>
                                                <v-row v-for="(item_title, ind) in variation_title" :key="ind + ind++">
                                                    <div class="title-field">{{ $t('general.variation-name') }}</div>
                                                    <input type="text"
                                                           v-model="item_title.title"
                                                           class="input-text">
                                                    <v-row style="width: 100%">
                                                        <v-col cols="8" class="pb-0 grey--text">
                                                            {{ $t('general.variation-value') }}
                                                        </v-col>
                                                        <v-col cols="3" class="pb-0 pl-0 grey--text">
                                                            {{ $t('general.price-change') }}
                                                        </v-col>
                                                        <v-col cols="1" class="pb-0"></v-col>
                                                    </v-row>
                                                    <div v-for="(item_variation, index) in variation_list"
                                                         :key="index + index++">
                                                        <v-row class="d-flex align-center"
                                                               v-if="item_title.id === item_variation.variation_id">
                                                            <v-col cols="8" class="pt-0">
                                                                <input type="text" class="input-text"
                                                                       v-model="item_variation.value">
                                                            </v-col>
                                                            <v-col cols="3" class="pl-0 pt-0">
                                                                <input type="number" class="input-text" min="0"
                                                                       v-model="item_variation.price">
                                                            </v-col>
                                                            <v-col cols="1" class="text--error pl-0 pt-0">
                                                                <button class="red--text"
                                                                        @click="delVariationList(index - 1)">&times;
                                                                </button>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <v-row class="d-flex align-center">
                                                        <v-col cols="6">
                                                            <button class="add_variation_item"
                                                                    @click="addVariationItem(item_title.id)">
                                                                {{ $t('general.add-variation-item') }}
                                                            </button>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <button class="delete_variation"
                                                                    @click="delVariation(ind - 1, item_title.id)">
                                                                {{ $t('general.delete-variation') }}
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-row>
                                            </v-card>
                                            <button class="add_variation" @click="addVariation(indexNewVariation)">
                                                {{ $t('general.add-variation') }}
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="btn_submit" text @click="save">
                                    {{ $t('general.submit') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5"
                            >{{ $t('general.are-you-sure-you-want') }}
                            </v-card-title
                            >
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                >{{ $t('general.cancel') }}
                                </v-btn
                                >
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                >{{ $t('general.ok') }}
                                </v-btn
                                >
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.is_active="{ item }">
                <span :class="getActiveClass(item)">{{ $t(getActiveStatus(item)) }}</span>
            </template>
            <template v-slot:item.description="{ item }">
                <span v-if="item.description">
                    <span v-if="item.description.length > 20">{{ item.description.slice(0, 20) + ' ...' }}</span>
                    <span v-else>{{ item.description }}</span>
                </span>
            </template>
            <template v-slot:item.allergenen="{ item }">
                <span v-if="item.allergenen">
                    <span v-if="item.allergenen.length > 20">{{ item.allergenen.slice(0, 20) + ' ...' }}</span>
                    <span v-else>{{ item.allergenen }}</span>
                </span>
            </template>
            <template v-slot:item.ingredients="{ item }">
                <span v-if="item.ingredients">
                    <span v-if="item.ingredients.length > 20">{{ item.ingredients.slice(0, 20) + ' ...' }}</span>
                    <span v-else>{{ item.ingredients }}</span>
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data></template>
        </v-data-table>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import Dropzone from "../../../components/Dropzone";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {cloneDeep} from "lodash";

export default {
    components: {UserDashboardLayout, Dropzone},
    data: () => ({
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: "Image",
                value: "image",
            },
            {
                text: "Name",
                value: "name",
            },
            {
                text: "Active",
                value: "is_active",
            },
            {
                text: "Price",
                value: "price",
            },
            {
                text: "Description",
                value: "description"
            },
            {
                text: "Allergenen",
                value: "allergenen"
            },
            {
                text: "Ingredients",
                value: "ingredients"
            },
            {
                text: "Categories",
                value: "categories",
            },
            {
                text: "Actions",
                value: "actions",
            }
        ],
        pagination: {
            page: 1,
            itemsPerPage: 15
        },
        editedIndex: -1,
        image_url: '',
        loading: false,
        dropzone_height: '37px',
        show_view_variation: false,
        variation_title: [{id: '1', title: ''}],
        variation_list: [{value: '', price: '', variation_id: '1'}],
        indexNewVariation: 2
    }),

    computed: {
        ...mapGetters(["defaultProduct", "product", "products", "categories"]),
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    mounted() {
        this.init();
        this.setCategories({page: 1, itemsPerPage: -1});
    },

    methods: {
        ...mapActions([
            "storeProduct",
            "setProducts",
            "setCategories",
            "updateProduct",
            "destroyProduct"
        ]),
        ...mapMutations(["setProduct"]),

        async init() {
            this.loading = true;
            this.setProducts(this.pagination)
            this.loading = false;
        },

        newItem() {
            this.setProduct(cloneDeep(this.defaultProduct));
            this.show_view_variation = false
            this.variation_title = [{id: '1', title: ''}]
            this.variation_list = [{value: '', price: '', variation_id: '1'}]
        },

        editItem(item) {
            this.show_view_variation = false
            this.editedIndex = this.products.data.findIndex(
                i => i.id === item.id
            );
            this.setProduct(item);
            this.dialog = true;
            this.image_url = item.image
            if (item.variation_title) {
                this.variation_title = JSON.parse(item.variation_title)
                this.variation_list = JSON.parse(item.variation_list)
                this.show_view_variation = true
                this.indexNewVariation = this.variation_title.length + 1
            }
            this.dropzone_height = '200px'
        },

        deleteItem(item) {
            this.editedIndex = this.products.data.findIndex(
                i => i.id === item.id
            );
            this.setProduct(item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            await this.destroyProduct(this.product);
            this.init();
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.setProduct(this.defaultProduct);
                this.editedIndex = -1;
            });
            this.product = ''
            this.image_url = ''
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.setProduct(this.defaultProduct);
                this.editedIndex = -1;
            });
        },

        async save() {
            if (this.variation_title.length > 0) {
                this.product.variation_title = JSON.stringify(this.variation_title)
            } else {
                this.product.variation_title = JSON.stringify([])
            }
            if (this.variation_list.length > 0) {
                this.product.variation_list = JSON.stringify(this.variation_list)
            } else {
                this.product.variation_list = JSON.stringify([])
            }

            if (this.editedIndex > -1) {
                await this.updateProduct(this.product);
            } else {

                await this.storeProduct(this.product);
            }
            this.init();

            this.variation_title = [{id: '', title: ''}]
            this.variation_list = [{value: '', price: '', variation_id: ''}]
            this.close();
        },

        paginate(pagination) {
            this.pagination = pagination;
            this.setProducts(pagination);
        },

        handleImgProduct() {
            this.product.image = this.image_url
        },
        addVariation(index) {
            if (this.show_view_variation === false) {
                this.show_view_variation = true
            } else {
                this.variation_title.push({id: index.toString(), title: ''})
                this.indexNewVariation = index + 1
            }
        },
        addVariationItem(id) {
            if (this.show_view_variation === false) {
                this.show_view_variation = true
            } else {
                this.variation_list.push({value: '', price: '', variation_id: id})
            }
        },
        delVariationList(index) {
            if (this.variation_list.length > 0) {
                this.variation_list.splice(index, 1)
            } else {
                this.show_view_variation = false
            }
        },
        delVariation(index, id) {
            if (this.variation_title.length > 0) {
                this.variation_list = this.variation_list.filter(item => item.variation_id !== id)
                this.variation_title.splice(index, 1)
                this.indexNewVariation = index - 1
            } else {
                this.show_view_variation = false
            }
        },
        getActiveStatus(item) {
            if (item.is_active) {
                return 'general.activated';
            }
            return 'general.not_activated';
        },
        getActiveClass(item) {
            if (item.is_active) {
                return 'green--text text--accent-4';
            }
            return 'red--text text--accent-4';
        },
    }
};
</script>

<style>
thead.v-data-table-header {
    display: none !important;
}

#form-product .title-field {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #232020;
    margin-bottom: 7px;
    margin-top: 15px;
}

#form-product .height-37px {
    height: 37px !important;
}

#form-product .form-product__textarea {
    box-sizing: border-box;
    width: 100%;
    height: 85px;
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 5px;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 9px 16px;
}

#form-product .input-text {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 37px;
    background: #FFFFFF;
    border: 1px solid #CECECE;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    border-radius: 5px;
    padding: 9px 16px;
    box-shadow: none;
}

#form-product .categories_select,
#form-product .categories_select .v-select__slot,
#form-product .categories_select .v-input__control,
#form-product .categories_select fieldset {
    box-sizing: border-box;
    width: 100%;
    height: 37px;
    background: #FFFFFF;

    border: 1px solid transparent;
    border-radius: 5px;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* padding: 5px 15px; */
}

#form-product .categories_select {
    border: 1px solid #CECECE;
}

#form-product .wrap_select {
    padding-bottom: 5px;
    margin-bottom: 5px;
}

#form-product .categories_select .v-select__slot {
    padding-top: 0;
    margin-top: 0;
    background: transparent;
}

#form-product .categories_select .v-input__append-inner {
    margin-top: 5px;
    transform: translateX(10px);
}

#form-product .categories_select .v-input__control {
    height: 35px;
}

.position-relative {
    position: relative;
}

.close_dialog,
.close_dialog:hover,
.close_dialog:before,
.close_dialog:active,
.close_dialog:focus {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent !important;
    box-shadow: none;
    transform: translateX(10px);
}

.close_dialog .v-btn__content {
    font-size: 16px;
}

.add_variation {
    box-sizing: border-box;
    width: 195px;
    height: 37px;
    background: #FFFFFF;
    border: 1px dashed #1E5CBA;
    border-radius: 5px;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #1E5CBA;
}

.add_variation_item {
    box-sizing: border-box;
    width: 195px;
    height: 37px;
    background: #FFFFFF;
    border: 1px dashed #1E5CBA;
    border-radius: 5px;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #1E5CBA;
    margin: 12px;
}

.delete_variation {
    box-sizing: border-box;
    width: 195px;
    height: 37px;
    background: #FFFFFF;
    border: 1px solid red;
    border-radius: 5px;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: red;
    margin: 12px;
}

.variation-card {
    box-shadow: none !important;
    min-width: 100%;
    margin-bottom: 15px;
}

.btn_submit {
    background: #1E5CBA;
    min-width: 100% !important;
    height: 37px !important;
    color: #fff !important;
}
</style>
