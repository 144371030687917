<template>
    <div>
        <div class="wrap_dropzone">
            <vue-dropzone
                :style="{'height': dropzone_height, 'min-height': dropzone_height}"
                ref="myVueDropzone" id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-success="handleSuccess"
            ></vue-dropzone>
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "dropzone",
    components: {
        vueDropzone: vue2Dropzone
    },
    props: ['current_url', 'mode_dropzone_height'],
    data: () => ({
        dropzoneOptions: {
            url: '/api/upload',
            thumbnailWidth: 150,
            thumbnailHeight: 150,
            maxFilesize: 5,
            headers: {
                Authorization: null
            },
            dictDefaultMessage: 'Drag&drop image'
        },
        dropzone_height: ''
    }),
    methods: {
        handleSuccess(file, res) {
            this.$emit('input', res.url);
            this.dropzone_height = '170px'
            this.dropzoneOptions.dictDefaultMessage = ''
        },
    },
    watch: {
        current_url() {
            if (this.current_url) {
                this.$refs.myVueDropzone.removeAllFiles();
                let file = {
                    size: 300,
                    name: this.current_url,
                    url: this.current_url,
                    thumbnail: this.current_url
                };
                this.$refs.myVueDropzone.manuallyAddFile(file, this.current_url);
            } else {
                this.$refs.myVueDropzone.removeAllFiles();
            }
        },
    },
    async mounted() {
        const token = await localStorage.getItem('auth_token_default')
        this.dropzoneOptions.headers.Authorization = "Bearer " + token
        this.$refs.myVueDropzone.removeAllFiles();
        let file = {
            size: 300,
            name: this.current_url,
            url: this.current_url,
            thumbnail: this.current_url
        };
        this.$refs.myVueDropzone.manuallyAddFile(file, this.current_url);
        if(this.mode_dropzone_height) {
            this.dropzone_height = this.mode_dropzone_height
        } else {
            this.dropzone_height = '200px'
        }
    }
}
</script>

<style>
#dropzone .dz-clickable.dz-started {
    text-align: center;
}

#dropzone {
    box-sizing: border-box;
    width: 100%;
    background: #FFFFFF;
    border: 1px dashed #CECECE;
    border-radius: 5px;
    max-height: 37px;
    min-height: 37px;
    height: 37px;
}

.dropzone .dz-preview,
#dropzone .dz-preview.dz-file-preview.dz-complete,
#dropzone .dz-preview {
    overflow: hidden;
    min-width: 0;
    min-height: 0;
    margin: 0;
    padding: 0;
}

#dropzone {
    height: 37px;
    padding: 0;
    color: #b6b6b6;
    background: #fff;
    position: relative;
}

/*#dropzone .dz-preview {
    width: 160px;
}*/

#dropzone .vue-dropzone > .dz-preview .dz-image img:not([src]),
.vue-dropzone > .dz-preview .dz-image img:not([src]) {
    width: 0 !important;
    height: 0 !important;
}

.dz-preview.dz-complete.dz-image-preview {
    position: absolute;
    top: 10px;
    left: calc(50% - 75px);
}

.dropzone.dz-started .dz-message {
    display: block;
    margin: 7px auto;
}
</style>
